/*  Colors */
/* Fonts */
/*  Sizes */
/*  Padding */
/*  Padding sections */
/*  Padding columns */
/* Breakpoints */
/* Navigation */
/* Animation speed */
/* Swiper */
.contact_form__column, .contact_form__row {
  display: flex; }

.contact_form__column {
  flex-wrap: wrap; }

.contact_form__row {
  margin-right: -15px;
  margin-left: -15px;
  flex-direction: column; }
  @media (min-width: 768px) {
    .contact_form__row {
      flex-direction: row;
      margin-right: -15px;
      margin-left: -15px; } }

.contact_form__column {
  flex: 1 1 10%; }
  .contact_form__column .contact_form__field {
    width: 100%; }

.contact_form__field {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 768px) {
    .contact_form__field {
      padding-right: 15px;
      padding-left: 15px; } }
  .contact_form__field:not(:last-child) {
    margin-bottom: 1rem; }

.contact_form .error {
  border: 1px solid #dc3545; }

.contact_form select.error + div {
  border: 1px solid #dc3545; }

.contact_form .radio_check__result.error ~ .contact_form__field__radio_check label {
  color: #dc3545; }

.contact_form .hide {
  display: none; }

.contact_form .file__drop,
.contact_form .selector,
.contact_form input,
.contact_form textarea {
  border: 1px solid black; }

.notification {
  position: relative; }
  .notification .message {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0; }
  .notification.success .message__success {
    opacity: 1; }
  .notification.error .message__error {
    opacity: 1; }

.grecaptcha-badge {
  display: none; }

.selector {
  cursor: pointer;
  position: relative;
  z-index: 5; }
  .selector__wrapper select {
    display: none; }
  .selector__input {
    padding: 1rem;
    background: lightgrey;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
    .selector__input svg {
      margin-left: 2rem;
      height: 1rem;
      pointer-events: none;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
  .selector__value {
    display: none; }
  .selector__list {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    max-height: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    overflow: hidden; }
    .selector__list__item {
      padding: 1rem;
      background: #f0f0f0; }
  .selector--open {
    z-index: 11; }
    .selector--open .selector__list {
      max-height: 200px; }
    .selector--open svg {
      transform: rotate(180deg); }
  .selector--closing {
    z-index: 11; }
  .selector--selected .selector__value {
    display: block; }
  .selector--selected .selector__placeholder {
    display: none; }

.file__drop {
  position: relative; }

.file__overlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.file input {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%; }

.file .drag_over {
  border-style: dashed; }

.file .has_file {
  background: green; }

.file__remove {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%); }

.condition {
  display: flex;
  align-items: center; }
  .condition__input {
    position: relative;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    border: 1px solid black; }
  .condition__fake-input,
  .condition input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .condition__fake-input::before {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: 80%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    content: "";
    background-color: transparent; }
  .condition input {
    opacity: 0;
    z-index: 10;
    position: relative; }
    .condition input:checked + .condition__fake-input::before {
      background-color: black; }

[hidden] {
  display: block !important; }
